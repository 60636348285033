exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-audio-js": () => import("./../../../src/pages/audio.js" /* webpackChunkName: "component---src-pages-audio-js" */),
  "component---src-pages-causepapers-js": () => import("./../../../src/pages/causepapers/[...].js" /* webpackChunkName: "component---src-pages-causepapers-js" */),
  "component---src-pages-collection-js": () => import("./../../../src/pages/collection.js" /* webpackChunkName: "component---src-pages-collection-js" */),
  "component---src-pages-formtest-js": () => import("./../../../src/pages/formtest.js" /* webpackChunkName: "component---src-pages-formtest-js" */),
  "component---src-pages-iiif-image-api-compliance-js": () => import("./../../../src/pages/iiif-image-API-compliance.js" /* webpackChunkName: "component---src-pages-iiif-image-api-compliance-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-viewitem-js": () => import("./../../../src/pages/viewitem.js" /* webpackChunkName: "component---src-pages-viewitem-js" */),
  "component---src-templates-causepapers-overview-template-js": () => import("./../../../src/templates/causepapersOverviewTemplate.js" /* webpackChunkName: "component---src-templates-causepapers-overview-template-js" */),
  "component---src-templates-collection-template-js": () => import("./../../../src/templates/collectionTemplate.js" /* webpackChunkName: "component---src-templates-collection-template-js" */),
  "component---src-templates-collection-template-v-2-js": () => import("./../../../src/templates/collectionTemplate-v2.js" /* webpackChunkName: "component---src-templates-collection-template-v-2-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/homeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */)
}

